import React, { SVGProps } from 'react'

const FlagNorway = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#EF2B2D"
      d="M5.556 0H2.222C1.632 0 1.068.244.651.68A2.371 2.371 0 0 0 0 2.318v3.479h5.556V0Zm12.222 0h-8.89v5.798H20V2.319c0-.615-.234-1.205-.65-1.64A2.176 2.176 0 0 0 17.777 0ZM5.556 15.074H2.222c-.59 0-1.154-.245-1.571-.68A2.371 2.371 0 0 1 0 12.754V9.277h5.556v5.798Zm12.222 0h-8.89V9.276H20v3.479c0 .615-.234 1.204-.65 1.64-.418.434-.983.679-1.572.679Z"
    />
    <path
      fill="#002868"
      d="M8.056 0H6.42l-.014 6.667H0v1.74h6.403l-.014 6.667h1.667V8.406H20V6.667H8.056V0Z"
    />
    <path
      fill="#EEE"
      d="M8.056 15.074h.833V9.276H20v-.87H8.056v6.668ZM8.889 0h-.833v6.667H20v-.87H8.889V0Zm-2.5 0h-.833v5.798H0v.87h6.389V0ZM0 8.406v.87h5.556v5.798h.833V8.406H0Z"
    />
  </svg>
)

export default FlagNorway
