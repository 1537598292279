import React, { SVGProps } from 'react'

const FlagFrance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#ED2939"
      d="M20 12.755c0 .615-.234 1.204-.65 1.64-.418.434-.983.679-1.572.679h-4.445V0h4.445c.59 0 1.154.244 1.571.68.417.434.651 1.024.651 1.639v10.436Z"
    />
    <path
      fill="#002495"
      d="M2.222 0C1.632 0 1.068.244.651.68A2.371 2.371 0 0 0 0 2.318v10.436c0 .615.234 1.204.65 1.64.418.434.983.679 1.572.679h4.445V0H2.222Z"
    />
    <path fill="#EEE" d="M6.667 0h6.666v15.074H6.667V0Z" />
  </svg>
)

export default FlagFrance
