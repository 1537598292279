import styled from '@emotion/styled'
import Link from 'next/link'
import React, { useCallback } from 'react'

import { minWidth } from '@emico/styles'
import { H2 } from '@emico/ui'

import storeSelectorCountries, {
  storeSelectorGlobals,
} from '../lib/getStoreSelectorCountries'
import theme from '../theme'

const CountryList = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  gap: ${theme.spacing.xs};

  @media ${minWidth('sm')} {
    grid-template-columns: 1fr 1fr;
  }
`

const Country = styled(Link, {
  shouldForwardProp: (prop) => !['isDisabled'].includes(prop.toString()),
})<{ isDisabled?: boolean }>`
  background: ${theme.colors.grayLight};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.lg};
  text-transform: uppercase;
  color: inherit;
  font-weight: ${theme.fontWeights.medium};
  font-size: ${theme.fontSizes.sm};
  border-radius: ${theme.borderRadius.base};
  text-decoration: none;
  transition-property: ${theme.transition.properties.colors};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};

  :hover,
  &:focus {
    text-decoration: none;
    background: ${theme.colors.grayMiddle};
    color: inherit;
  }
`

const Subtitle = styled(H2)`
  font-size: ${theme.fontSizes.lg};
  margin: ${theme.spacing.xl} 0 ${theme.spacing.md};
`

interface Props {
  isDisabled?: boolean
}

const StoreSelectorCountryList = ({ isDisabled, ...other }: Props) => {
  const handleSelection = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      const language = e.currentTarget.getAttribute('href')

      const headers = new Headers()

      headers.append('Content-Type', 'application/json')

      // Send the selected language to the /api/select-language endpoint, this endpoint will store
      // the selected language in a cookie so the user will be redirected next time.
      fetch('/api/select-language', {
        method: 'POST',
        headers,
        body: JSON.stringify({
          language,
        }),
      })
    },
    [],
  )

  return (
    <div {...other}>
      <CountryList>
        {storeSelectorCountries.map((country) => (
          <Country
            key={country.base}
            href={country.base}
            locale={false}
            onClick={handleSelection}
            prefetch={false}
            isDisabled={isDisabled}
            tabIndex={isDisabled ? -1 : 0}
          >
            {country.flag}
            {country.name}
          </Country>
        ))}
      </CountryList>

      <Subtitle>Other countries</Subtitle>

      <CountryList>
        {storeSelectorGlobals.map((global) => (
          <Country
            key={global.base}
            href={global.base}
            locale={false}
            onClick={handleSelection}
            prefetch={false}
            isDisabled={isDisabled}
            tabIndex={isDisabled ? -1 : 0}
          >
            {global.flag}
            {global.name}
          </Country>
        ))}
      </CountryList>
    </div>
  )
}

export default StoreSelectorCountryList
