import React, { SVGProps } from 'react'

const FlagNetherlands = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path fill="#EEE" d="M0 5.218h20v4.638H0V5.218Z" />
    <path
      fill="#AE1F28"
      d="M17.778 0H2.222C1.632 0 1.068.244.651.68A2.371 2.371 0 0 0 0 2.318v2.899h20V2.319c0-.615-.234-1.205-.65-1.64A2.176 2.176 0 0 0 17.777 0Z"
    />
    <path
      fill="#20478B"
      d="M2.222 15.074h15.556c.59 0 1.154-.245 1.571-.68.417-.434.651-1.024.651-1.64V9.857H0v2.899c0 .615.234 1.204.65 1.64.418.434.983.679 1.572.679Z"
    />
  </svg>
)

export default FlagNetherlands
