import React, { SVGProps } from 'react'

const FlagUS = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#B22334"
      d="M19.692 1.16C19.307.469 18.598 0 17.778 0H10v1.16h9.692ZM0 11.595h20v1.16H0v-1.16Zm10-4.638h10v1.16H10v-1.16Zm0-2.319h10v1.16H10v-1.16ZM0 9.276h20v1.16H0v-1.16Zm2.222 5.798h15.556c.82 0 1.529-.47 1.914-1.16H.308c.385.69 1.094 1.16 1.914 1.16ZM10 2.319h10v1.16H10v-1.16Z"
    />
    <path
      fill="#EEE"
      d="M.038 13.148a2.24 2.24 0 0 0 .084.332c.05.15.11.295.185.431l.001.003h19.384v-.002a2.501 2.501 0 0 0 .27-.764 2.24 2.24 0 0 0 .038-.393H0c0 .135.016.265.038.393ZM0 10.435h20v1.16H0v-1.16Zm0-2.318v1.159h20v-1.16H0Zm10-2.32h10v1.16H10v-1.16Zm0-2.319h10v1.16H10v-1.16ZM.308 1.16l-.001.003.001-.003Zm-.237.606c.014-.06.033-.116.051-.172-.02.056-.036.114-.05.172ZM10 2.319h10c0-.135-.016-.266-.038-.394a2.17 2.17 0 0 0-.085-.334 2.498 2.498 0 0 0-.185-.432H10v1.16Z"
    />
    <path
      fill="#3C3B6E"
      d="M10 0H2.222C1.632 0 1.068.244.651.68A2.371 2.371 0 0 0 0 2.318v5.798h10V0Z"
    />
    <path
      fill="#fff"
      d="m1.112 1.58.343.26-.131.421.343-.26.343.26-.131-.42.343-.26h-.424l-.131-.422-.13.421h-.425Zm1.11 1.16.344.26-.13.42.342-.26.343.26-.13-.42.342-.26H2.91l-.131-.421-.13.42h-.425Zm2.223 0 .343.26-.13.42L5 3.16l.343.26-.13-.42.343-.26H5.13l-.13-.421-.131.42h-.425Zm2.222 0 .344.26-.131.42.342-.26.344.26L7.435 3l.343-.26h-.425l-.13-.421-.131.42h-.425ZM2.223 5.059l.343.26-.13.42.342-.26.343.26-.13-.42.342-.26H2.91l-.131-.421-.13.42h-.425Zm2.222 0 .343.26-.13.42L5 5.48l.343.26-.13-.42.343-.26H5.13l-.13-.421-.131.42h-.425Zm2.222 0 .344.26-.131.42.342-.26.344.26-.131-.42.343-.26h-.425l-.13-.421-.131.42h-.425ZM3.334 1.58l.343.26-.13.421.342-.26.343.26-.13-.42.343-.26H4.02l-.131-.422-.13.421h-.425Zm2.222 0 .344.26-.132.421.343-.26.344.26-.132-.42.344-.26h-.425l-.13-.422-.131.421h-.425Zm2.222 0 .344.26-.131.421.342-.26.344.26-.131-.42.343-.26h-.424l-.132-.422-.13.421h-.425ZM1.112 3.9l.343.26-.131.42.343-.26.343.26-.131-.42.343-.26h-.424l-.131-.422-.13.421h-.425Zm2.434.68.343-.26.343.26-.13-.42.343-.26H4.02l-.131-.422-.13.421h-.425l.343.26-.13.421Zm2.01-.68.344.26-.132.42.343-.26.344.26-.132-.42.344-.26h-.425l-.13-.422-.131.421h-.425Zm2.222 0 .344.26-.131.42.342-.26.344.26-.131-.42.343-.26h-.424l-.132-.422-.13.421h-.425ZM1.112 6.217l.343.26-.131.421.343-.26.343.26-.131-.42.343-.26h-.424l-.131-.421-.13.42h-.425Zm2.434.681.343-.26.343.26-.13-.42.343-.26H4.02l-.131-.421-.13.42h-.425l.343.26-.13.421Zm2.01-.68.344.26-.132.42.343-.26.344.26-.132-.42.344-.26h-.425l-.13-.421-.131.42h-.425Zm2.222 0 .344.26-.131.42.342-.26.344.26-.131-.42.343-.26h-.424l-.132-.421-.13.42h-.425Z"
    />
  </svg>
)

export default FlagUS
