import React, { SVGProps } from 'react'

const FlagBelgium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#141414"
      d="M2.5 0C1.837 0 1.201.244.732.68A2.237 2.237 0 0 0 0 2.318v10.436c0 .615.263 1.204.732 1.64.47.434 1.105.679 1.768.679h3.75V0H2.5Z"
    />
    <path fill="#FDDA24" d="M6.25 0h7.5v15.074h-7.5V0Z" />
    <path
      fill="#ED1F24"
      d="M17.5 0h-3.75v15.074h3.75c.663 0 1.299-.245 1.768-.68a2.237 2.237 0 0 0 .732-1.64V2.32c0-.615-.263-1.205-.732-1.64A2.603 2.603 0 0 0 17.5 0Z"
    />
  </svg>
)

export default FlagBelgium
