import React, { SVGProps } from 'react'

const FlagDenmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#C60C30"
      d="M17.778 0H8.333v6.377H20V2.32c0-.615-.234-1.205-.65-1.64A2.176 2.176 0 0 0 17.777 0ZM8.333 15.074h9.445c1.227 0 2.222-1.039 2.222-2.61V8.697H8.333v6.378ZM0 8.696v3.769c0 1.57.995 2.609 2.222 2.609h3.89V8.696H0ZM6.111 0H2.222C1.632 0 1.068.244.651.68A2.371 2.371 0 0 0 0 2.318v4.058h6.111V0Z"
    />
    <path
      fill="#EEE"
      d="M8.333 0H6.111v6.377H0v2.32h6.111v6.377h2.222V8.696H20V6.377H8.333V0Z"
    />
  </svg>
)

export default FlagDenmark
