import React, { SVGProps } from 'react'

const FlagUK = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#00247D"
      d="M0 2.353v2.285h3.127L0 2.353Zm2.591 12.72h4.631V11.69l-4.63 3.384Zm10.187-3.383v3.384h4.63l-4.63-3.384ZM0 10.436v2.284l3.128-2.284H0ZM17.41 0h-4.632v3.384L17.409 0ZM20 12.721v-2.285h-3.128L20 12.72Zm0-8.083V2.353l-3.127 2.285H20ZM7.222 0h-4.63l4.63 3.384V0Z"
    />
    <path
      fill="#CF1B2B"
      d="m13.967 10.435 5.395 3.943c.264-.283.453-.632.55-1.014l-4.008-2.929h-1.937Zm-6.745 0h-1.19L.639 14.378c.29.307.66.527 1.076.629l5.508-4.025v-.546Zm5.556-5.797h1.19L19.361.696a2.193 2.193 0 0 0-1.076-.63l-5.508 4.025v.547Zm-6.745 0L.638.696a2.343 2.343 0 0 0-.55 1.014l4.008 2.928h1.937Z"
    />
    <path
      fill="#EEE"
      d="M20 9.276h-8.333v5.798h1.11V11.69l4.631 3.384h.37c.295 0 .587-.062.86-.181.271-.12.518-.294.724-.514l-5.395-3.943h1.937l4.008 2.928c.052-.195.088-.397.088-.61v-.033l-3.128-2.285H20v-1.16Zm-20 0v1.16h3.128L0 12.72v.035c0 .632.244 1.204.638 1.623l5.395-3.942h1.19v.546l-5.509 4.024c.164.04.333.068.508.068h.37l4.63-3.384v3.384h1.111V9.276H0ZM20 2.32c0-.608-.229-1.19-.638-1.623l-5.395 3.942h-1.19v-.547l5.51-4.024a2.135 2.135 0 0 0-.51-.067h-.368l-4.631 3.384V0h-1.111v5.798H20v-1.16h-3.127L20 2.353V2.32ZM7.222 0v3.384L2.592 0h-.37c-.295 0-.587.061-.86.18C1.092.3.845.476.639.697l5.395 3.942H4.096L.087 1.71A2.393 2.393 0 0 0 0 2.319v.034l3.127 2.285H0v1.16h8.333V0h-1.11Z"
    />
    <path
      fill="#CF1B2B"
      d="M11.667 5.798V0H8.333v5.798H0v3.478h8.333v5.798h3.334V9.276H20V5.798h-8.333Z"
    />
  </svg>
)

export default FlagUK
