import React, { SVGProps } from 'react'

const FlagSweden = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#006AA7"
      d="M8.611 15.074h9.167c1.227 0 2.222-1.039 2.222-2.61V8.987H8.611v6.088ZM17.778 0H8.61v6.087H20V2.32c0-.615-.234-1.205-.65-1.64A2.176 2.176 0 0 0 17.777 0ZM5.833 0h-3.61C1.632 0 1.067.244.65.679A2.371 2.371 0 0 0 0 2.317v3.77h5.833V0ZM0 8.986v3.481c.001 1.57.996 2.607 2.222 2.607h3.611V8.986H0Z"
    />
    <path
      fill="#FECC00"
      d="M8.611 0H5.833v6.087H0v2.9h5.833v6.087h2.778V8.986H20V6.087H8.611V0Z"
    />
  </svg>
)

export default FlagSweden
