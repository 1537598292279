import React, { SVGProps } from 'react'

const FlagIreland = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#169B62"
      d="M2.222 0C1.632 0 1.068.244.651.68A2.371 2.371 0 0 0 0 2.318v10.436c0 .615.234 1.204.65 1.64.418.434.983.679 1.572.679h4.445V0H2.222Z"
    />
    <path fill="#EEE" d="M6.667 0h6.666v15.074H6.667V0Z" />
    <path
      fill="#FF883E"
      d="M17.778 0h-4.445v15.074h4.445c.59 0 1.154-.245 1.571-.68.417-.434.651-1.024.651-1.64V2.32c0-.615-.234-1.205-.65-1.64A2.176 2.176 0 0 0 17.777 0Z"
    />
  </svg>
)

export default FlagIreland
